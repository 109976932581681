import React, { useContext, useEffect } from 'react';
import { Link } from 'gatsby';

import PersonalizedPageContent from '@components/personalized-page-content/personalized-page-content';
import Layout from '@components/layout';
import { useProductPersonalisedText } from '@hooks/directusHooks/useProductPersonalisedText';
import SEO from '@components/seo';
import Image from '@components/image/image';
import { isUsingGBPCurrency } from '@services/global';
import AddToBasket from '@components/add-to-basket';
import Loader from '@components/loader';
import { ERROR_MESSAGES } from '@constants/errorMessages';
import usePersonalizePlan from '@hooks/usePersonalizePlan';
import useGlobalError from '@hooks/useGlobalError';

import styles from './product/personalised.module.scss';
import { GlobalContext } from '@src/store/global-state';
import couponCodeService from '@src/utils/couponCodeService';

const PersonalizedOff30 = () => {
  const { directusProductPersonalised, directus30Off3Month } = useProductPersonalisedText();
  const { isLoggedIn } = useContext(GlobalContext);
  const { setOffer } = couponCodeService();

  const {
    getRecommendedProducts,
    loadingProducts,
    loadingPlans,
    errorProducts,
    errorPlans,
    plans,
  } = usePersonalizePlan();

  useEffect(() => {
    setOffer('4pp', '30off');
  }, []);

  useEffect(() => {
    if (isLoggedIn) getRecommendedProducts();
  }, [isLoggedIn]);

  const error = errorProducts || errorPlans;
  useGlobalError(!!error, ERROR_MESSAGES.generic);

  if (loadingProducts || loadingPlans) return <Loader />;

  return (
    <Layout showFooter={false}>
      <SEO
        title={directusProductPersonalised.page_title}
        description={directusProductPersonalised.page_description}
      />
      <section className={styles.sectionHero}>
        <div className={styles.heroContainer}>
          <div className={styles.heroRow}>
            <div className={styles.imageLeftLarge}>
              <Image
                filename={directusProductPersonalised.hero_image.filename_disk}
                alt={directusProductPersonalised.hero_image.title}
                width={540}
              />
            </div>
            <div className={styles.contentWrapperLeft}>
              <div className={styles.heroContent}>
                <h1>
                  {isUsingGBPCurrency()
                    ? directus30Off3Month.hero_title_trial
                    : directus30Off3Month.hero_title}
                </h1>
                <hr className={styles.hrCentered} />
                <div className={styles.heroDescription}>
                  <p>{directus30Off3Month.hero_description}</p>
                </div>
                {plans ? (
                  <div>
                    <AddToBasket plans={plans} />
                    {isUsingGBPCurrency() && (
                      <div className={styles.footnoteNoMargin}>
                        <p>{directus30Off3Month.feel_best_title}</p>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <Link className={styles.button} to={'/consultation'}>
                      {directus30Off3Month.hero_button_label}
                    </Link>
                    <p className={styles.footnote}>
                      {isUsingGBPCurrency() && (
                        <span>
                          {directus30Off3Month.free_uk_delivery}
                          <br />
                        </span>
                      )}
                      {directus30Off3Month.pause_or_cancel}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <PersonalizedPageContent />

      <section className={styles.sectionLast}>
        <div className={styles.rowLast}>
          <div className={styles.image}>
            <Image
              filename={directusProductPersonalised.feel_best_image.filename_disk}
              width={544}
            />
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              <h2>{directus30Off3Month.feel_best_title}</h2>
              <hr />
              <div>
                <p>{directus30Off3Month.feel_best_description}</p>
                {plans ? (
                  <div>
                    <AddToBasket plans={plans} />
                    {isUsingGBPCurrency() && (
                      <div className={styles.footnoteNoMargin}>
                        <p>{directus30Off3Month.feel_best_title}</p>
                      </div>
                    )}
                  </div>
                ) : (
                  <Link className={styles.button} to={'/consultation'}>
                    {directus30Off3Month.hero_button_label}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PersonalizedOff30;
